import request from "@/util/request";

// 信息维护
// 获取用户信息
export const getUserInfo = () => {
    return request({
        url: "/new_gateway/codepower-rest-api/user/getInfo",
        method: "get",
    });
};
// 更新用户信息
export const updateUserInfo = (data) => {
    return request({
        url: "/new_gateway/codepower-rest-api/user/updateInfo",
        method: "post",
        data,
    });
};
// 上传企业头像
export const uploadBusinessImage = data => {
    return request({
        url: "/new_gateway/codepower-rest-api/apply/uploadImages",
        method: "post",
        data: data,
        timeout: 300000
    });
};

// 发布产品
export const productRelease = (data) => {
    return request({
        url: "/new_gateway/codepower-rest-api/product/release",
        method: "post",
        data,
    });
};
// 我的产品列表
export const myProductList = (params) => {
    return request({
        url: "/new_gateway/codepower-rest-api/product/my/page",
        method: "get",
        params: params,
    });
};
// 查询通知记录
export const getWelcomePageInfo = (params) => {
    return request({
        url: "/new_gateway/codepower-rest-api/welcomePage",
        method: "get",
        params: params,
    });
};
// 产品详情(修改回显时用)
export const productInfo = (params) => {
    return request({
        url: "/new_gateway/codepower-rest-api/product/info",
        method: "get",
        params: params,
    });
};
//模块数据字典
export const moduleInfo = (params) => {
    return request({
        url: "/new_gateway/codepower-rest-api/productDic/list/MODULE",
        method: "get",
        params: params,
    });
};

// 文件确权
export const authRight = (data) => {
    return request({
        url: "/new_gateway/codepower-rest-api/product/authRight",
        method: "post",
        data,
        timeout: 3600000
    });
};

// 文件上传ipfs
export const ipfsUpload = (data) => {
    return request({
        url: "/new_gateway/codepower-rest-api/product/ipfsUpload",
        method: "post",
        data,
        timeout: 3600000
    });
};

// 用hash值确权
export const authRightWithHash = (data) => {
    return request({
        url: "/new_gateway/codepower-rest-api/product/authRightWithHash",
        method: "post",
        data,
        timeout: 3600000
    });
};

// 上传产品附件
export const uploadProductAccessory = (data) => {
    return request({
        url: "/new_gateway/codepower-rest-api/upload/productAccessory",
        method: "post",
        data,
        timeout: 600000
    });
};

// 上传产品logo&主页视频
export const uploadLogoAndVideo = (data) => {
    return request({
        url: "/new_gateway/codepower-rest-api/upload/logoAndVideo",
        method: "post",
        data,
        timeout: 600000
    });
};

// 用户申请
// 获取用户申请
export const getApplyList = (params) => {
    return request({
        url: "/new_gateway/codepower-rest-api/apply/getApplyList",
        method: "get",
        params: params,
    });
};
// 处理申请
export const handleApply = (data) => {
    return request({
        url: "/new_gateway/codepower-rest-api/apply/handleApply",
        method: "post",
        data: data,
    });
};

// 产品下载次数 /codepower-rest-api/welcomePage/getDownloadNum
export const getDownloadNum = (params) => {
    return request({
        url: "/new_gateway/codepower-rest-api/welcomePage/getDownloadNum",
        method: "get",
        params: params,
    });
};
