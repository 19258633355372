<template>
  <div>
    <el-card shadow="never" :body-style="{
      minHeight: 'calc(100vh - 153px)'
    }">
      <div class="title">
        <img
            v-if="companyInfo.companyLogo"
            :src="companyInfo.companyLogo"
            alt="图片加载失败"
        >
        <img
            v-else
            src="@/assets/kyyl-nt.png"
            alt="图片加载失败"
        >
      </div>
      <div class="content">
        <table class="table" cellspacing="0" cellpadding="0">
          <tr>
            <td>用户名称：</td>
            <td>{{ companyInfo.userName }}</td>
          </tr>
          <tr>
            <td>用户昵称：</td>
            <td>{{ companyInfo.nickName }}</td>
          </tr>
          <tr>
            <td>手机号：</td>
            <td>{{ companyInfo.phone }}</td>
          </tr>
          <tr>
            <td>电子邮箱：</td>
            <td>{{ companyInfo.email }}</td>
          </tr>
          <tr>
            <td>公司名称：</td>
            <td>{{ companyInfo.companyName }}</td>
          </tr>
          <tr>
            <td>公司官网：</td>
            <td>{{ companyInfo.companyWebsite }}</td>
          </tr>
          <tr>
            <td>企业地址：</td>
            <td><span style="line-height: 24px">{{ companyInfo.companyAddress }}</span></td>
          </tr>
          <tr>
            <td>成立时间：</td>
            <td>{{ companyInfo.establishTime }}</td>
          </tr>
          <tr>
            <td>企业规模：</td>
            <td>{{ companyInfo.companyScale }}</td>
          </tr>
          <tr>
            <td>注册资金：</td>
            <td>{{ companyInfo.registeredCapital }}</td>
          </tr>
          <tr style="border: none">
            <td style="border: none">公司简介：</td>
            <td style="border: none;">
              <div style="margin-top: 8px;margin-bottom: 8px">
                <div style="border: 1px solid #dcdfe6;padding: 4px;white-space: pre-line">
                  {{ companyInfo.companyIntroduction }}
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
      <div style="text-align: center;margin-top: 32px">
        <el-button type="primary" @click="edit" style="width: 160px">修 改</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import {Button, Card} from "element-ui"
import {getUserInfo} from "@/api/accountCenter";

export default {
  components: {
    [Card.name]: Card,
    [Button.name]: Button,
  },
  data() {
    return {
      companyInfo: {
        id: "",
        companyLogo: "",
        userName: "",
        nickName: "",
        phone: "",
        email: "",
        companyName: "",
        companyWebsite: "",
        companyAddress: "",
        establishTime: "",
        companyScale: "",
        registeredCapital: "",
        companyIntroduction: "",
      }
    }
  },
  mounted() {
    this.getCompanyInfo();
  },
  methods: {
    getCompanyInfo() {
      getUserInfo().then(res => {
        this.companyInfo.id = res.data.id;
        this.companyInfo.companyLogo = res.data.headImage;
        this.companyInfo.userName = res.data.userName;
        this.companyInfo.nickName = res.data.nickName;
        this.companyInfo.phone = res.data.mobile;
        this.companyInfo.email = res.data.email;
        this.companyInfo.companyName = res.data.companyName;
        this.companyInfo.companyWebsite = res.data.companyWebsite;
        this.companyInfo.establishTime = res.data.foundingTime;
        this.companyInfo.companyScale = res.data.companyScale;
        this.companyInfo.registeredCapital = res.data.registeredFunds;
        this.companyInfo.companyAddress = res.data.address;
        this.companyInfo.companyIntroduction = res.data.companyIntroduction;
      })
    },
    edit() {
      this.$router.push({
        name: 'editInformation'
      })
    }
  }
}

</script>

<style lang="scss" scoped>
.title {
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 48px;
  margin-top: 16px;

  img {
    width: 80px;
    height: 80px;
  }
}

.content {
  display: flex;
  justify-content: center;

  .table {

  }

  .table tr td {
    width: 280px;
    height: 48px;
    text-align: center;
    font-size: 14px;
    color: #303133;
    border-bottom: 2px solid #dcdfe6;
  }
}
</style>
